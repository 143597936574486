import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import colors from '../constants/colors'
import useFontFaceObserver from 'use-font-face-observer'

const Box = styled(motion.div)`
    background-color: ${colors.black};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 100%;
`

const variants = {
    enter: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
}

const transition = { duration: 0.5, ease: [0, 0.55, 0.45, 1] }

const Loader = () => {
    const [transitionStatus, setTransitionStatus] = useState('enter')
    const isFontListLoaded = useFontFaceObserver([
        { family: `pragmatica` },
        { family: `halogen` },
    ])

    useLayoutEffect(() => {
        if (isFontListLoaded && transitionStatus === 'enter')
            setTransitionStatus('exit')
    }, [transitionStatus, isFontListLoaded])

    return (
        <Box
            variants={variants}
            initial={{ opacity: 1 }}
            animate={transitionStatus}
            transition={transition}
        />
    )
}

export default Loader
